import { Cell, Tag } from '@rf-smart-for-oraclecloud/ui';
import {
    ProfileListColumnDefinition,
    ProfilesListEditableColumnType,
} from './types';
import { ProfileActionMenu } from './ProfileActionMenu';

export const columnDefinitions: Record<
    ProfilesListEditableColumnType,
    ProfileListColumnDefinition
> = {
    description: {
        label: ({ intl }) => intl.common.description(),
        template: ({ profile: { description } }) => (
            <Cell id="description">{description}</Cell>
        ),
        allowSort: true,
    },
    entries: {
        label: ({ intl }) => intl.profiles.entries(),
        template: ({ profile: { entries } }) => (
            <Cell id="entryCount">{(entries ?? []).length}</Cell>
        ),
    },
    isActive: {
        label: ({ intl }) => intl.profiles.status(),
        template: ({ intl, profile: { isActive } }) => (
            <Cell id="isActive">
                <Tag
                    style={{ width: 'fit-content' }}
                    accent="secondary"
                    family={isActive ? 'positive' : 'subtle'}
                >
                    {intl.profiles.isActiveText({
                        isActive,
                    })}
                </Tag>
            </Cell>
        ),
        allowSort: true,
    },
};

export const nameColumn: ProfileListColumnDefinition = {
    label: ({ intl }) => intl.common.name(),
    template: ({ profile: { name } }) => <Cell id="name">{name}</Cell>,
    allowSort: true,
};

export const actionsColumn: ProfileListColumnDefinition = {
    label: ({ intl }) => intl.profiles.actions(),
    template: ({ profile, onEdit, onSetActive, onDelete }) => (
        <Cell className="__action-column" id="actions">
            <ProfileActionMenu
                profile={profile}
                onEdit={onEdit}
                onSetActive={onSetActive}
                onDelete={onDelete}
            />
        </Cell>
    ),
    isActions: true,
};
