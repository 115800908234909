import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Form,
    Key,
    Label,
    Radio,
    RadioGroup,
    Select,
    Text,
    TextArea,
    TextInput,
} from '@rf-smart-for-oraclecloud/ui';
import { CreateWorkflowRequest } from '@@/types';
import { BasicModalLayout } from '@@/layout/BasicModal';
import { useAppIntl } from '@@/intl';
import { useCreateWorkflow } from './useCreateWorkflow';
import { useWorkflowCategories } from './useWorkflowCategories';
import { useWorkflowTemplates } from './useWorkflowTemplates';

const formDefaults: CreateWorkflowRequest = {
    category: '',
    name: '',
    description: '',
};

export function CreateWorkflowForm({
    onClose,
    refreshWorkflows,
}: CreateWorkflowFormProps) {
    const navigate = useNavigate();
    const [categoryType, setCategoryType] = useState<CategoryType>('new');
    const [workflowType, setWorkflowType] = useState<WorkflowType>('new');
    const intl = useAppIntl();
    const { createWorkflow, loading } = useCreateWorkflow();
    const { categories } = useWorkflowCategories();
    const { templates, existing } = useWorkflowTemplates();

    const [workflowForm, setWorkflowForm] = useState(formDefaults);

    const handleChangeCategoryType = (value: string) =>
        setCategoryType(value as CategoryType);
    const handleChangWorkflowType = (value: string) =>
        setWorkflowType(value as WorkflowType);

    const handleCategoryChange = createFieldChangeHandler<string>(
        'category',
        setWorkflowForm,
    );

    const handleChangeExistingCategory = (key: Key) => {
        if (typeof key === 'string') {
            handleCategoryChange(key);
        }
    };

    const handleNameChange = createFieldChangeHandler<string>(
        'name',
        setWorkflowForm,
    );
    const handleDescriptionChange = createFieldChangeHandler<string>(
        'description',
        setWorkflowForm,
    );

    const handleSubmit = async () => {
        const workflow = await createWorkflow(workflowForm);
        refreshWorkflows();

        if (workflow !== undefined) {
            navigate(`./${workflow.id}`);
        }
    };

    return (
        <BasicModalLayout
            title={intl.config.createWorkflow()}
            size="small"
            onClose={onClose}
            actions={
                <>
                    <Button family="subtle" variant="ghost" onClick={onClose}>
                        {intl.common.cancel()}
                    </Button>
                    <Button onClick={handleSubmit} isDisabled={loading}>
                        {intl.common.save()}
                    </Button>
                </>
            }
        >
            <div className="wf-create">
                <Section title={intl.config.category()}>
                    <RadioGroup
                        onChange={handleChangeCategoryType}
                        orientation="horizontal"
                        value={categoryType}
                    >
                        <Radio value="new">{intl.common.new()}</Radio>
                        <Radio value="existing">{intl.common.existing()}</Radio>
                    </RadioGroup>
                    {categoryType === 'new' && (
                        <TextInput
                            placeholder={intl.config.enterCategory()}
                            onChange={handleCategoryChange}
                        />
                    )}
                    {categoryType === 'existing' && (
                        <Select
                            placeholder={intl.config.selectCategory()}
                            onChange={handleChangeExistingCategory}
                            items={categories}
                            textSelector={(i) => i.name}
                            valueSelector={(i) => i.value}
                        />
                    )}
                </Section>
                <Section title={intl.config.workflowDetails()}>
                    <Label className="rfs-FieldLabel">
                        {intl.config.selectWorkflowType()}
                    </Label>
                    <RadioGroup
                        onChange={handleChangWorkflowType}
                        orientation="horizontal"
                        value={workflowType}
                    >
                        <Radio value="new">{intl.common.new()}</Radio>
                        <Radio value="existing">{intl.common.existing()}</Radio>
                        <Radio value="template">{intl.common.template()}</Radio>
                    </RadioGroup>
                    {workflowType === 'new' && (
                        <TextInput label="Name" onChange={handleNameChange} />
                    )}
                    {workflowType === 'existing' && (
                        <Select
                            placeholder={intl.common.selectExisting()}
                            onChange={handleChangeExistingCategory}
                            items={existing}
                            textSelector={(i) => i.name}
                            valueSelector={(i) => i.value}
                        />
                    )}
                    {workflowType === 'template' && (
                        <Select
                            placeholder={intl.common.selectTemplate()}
                            onChange={handleChangeExistingCategory}
                            items={templates}
                            textSelector={(i) => i.name}
                            valueSelector={(i) => i.value}
                        />
                    )}
                    <TextArea
                        label="Description"
                        onChange={handleDescriptionChange}
                        rows={5}
                    />
                </Section>
            </div>
        </BasicModalLayout>
    );
}

function Section({
    children,
    title,
}: Readonly<React.PropsWithChildren<{ title: string }>>) {
    return (
        <Form className="wf-create__section">
            <Text elementType="h4">{title}</Text>
            {children}
        </Form>
    );
}

function createFieldChangeHandler<TData>(
    name: string,
    setWorkflowForm: React.Dispatch<
        React.SetStateAction<CreateWorkflowRequest>
    >,
) {
    return (value: TData) =>
        setWorkflowForm((wf) => ({
            ...wf,
            [name]: value,
        }));
}

type CreateWorkflowFormProps = Readonly<{
    onClose: () => void;
    refreshWorkflows: () => void;
}>;

type CategoryType = 'new' | 'existing';
type WorkflowType = 'new' | 'existing' | 'template';
