import { WorkflowEditorStore } from '../types';

export const selectRoot = (state: WorkflowEditorStore) =>
    state.workflow.activityMap[state.workflow.activityTree[0].key];

export const selectSelectedActivity = (state: WorkflowEditorStore) =>
    state.workflow.selection === undefined ||
    state.workflow.selection.type !== 'activity'
        ? undefined
        : state.workflow.activityMap[state.workflow.selection.id];

export const selectCurrentActivityNodes = (state: WorkflowEditorStore) => {
    let currentActivities = state.workflow.activityTree;

    state.workflow.activityNav.forEach((navPath) => {
        currentActivities =
            currentActivities.find((c) => c.key === navPath.key)?.children?.[
                navPath.childKey
            ] ?? [];
    });

    return currentActivities;
};

export const selectCurrentActivities = (state: WorkflowEditorStore) =>
    selectCurrentActivityNodes(state).map(
        (a) => state.workflow.activityMap[a.key],
    );

export const selectBreadCrumb = (state: WorkflowEditorStore) =>
    state.workflow.activityNav.map((navPath) => ({
        id: `${navPath.key}-${navPath.childKey}`,
        navPath,
        spec: state.workflow.activityMap[navPath.key],
    }));

export const selectDesignerContext = (state: WorkflowEditorStore) => {
    const { activityMap, activityTree, activityNav } = state.workflow;
    return {
        activityMap,
        activityTree,
        activityNav,
    };
};
