import { useState, useEffect } from 'react';
import { useWorkflowsApi } from '@@/api/workflows';
import { useAppIntl } from '@@/intl';
import { useToast } from '@rf-smart-for-oraclecloud/ui';

export function useWorkflowCategories() {
    const intl = useAppIntl();
    const toast = useToast();
    const { listCategories } = useWorkflowsApi();
    const [data, setData] = useState<UseWorkflowCategoriesState>({
        loading: false,
        categories: [],
    });

    const handleResponse = (categories: string[]) =>
        setData((s) => ({
            ...s,
            categories: categories.map((c) => ({ name: c, value: c })),
        }));

    useEffect(() => {
        setData({ categories: [], loading: true });
        listCategories()
            .then(handleResponse)
            .catch(() =>
                toast.showErrorToast(intl.profiles.workflowsFetchFailed()),
            )
            .finally(() => setData((s) => ({ ...s, loading: false })));
    }, [intl.profiles, listCategories, toast]);

    return data;
}

type UseWorkflowCategoriesState = {
    loading: boolean;
    categories: { name: string; value: string }[];
};
