import { WorkflowActivity } from '@rf-smart-for-oraclecloud/wf-engine';
import { isWebActivity } from '@rf-smart-for-oraclecloud/wf-web';

export function getEditorMeta(activity: WorkflowActivity) {
    if (!isWebActivity(activity) || activity.editor === undefined) {
        return undefined;
    }

    return activity.editor;
}
