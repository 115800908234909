import { useMemo } from 'react';
import './styles.css';
import { NoItems } from '@rf-smart-for-oraclecloud/platform-ui';

export function CardCategoryList<TItem>({
    items,
    loading,
    selectCategory,
    children,
}: CardCategoryListProps<TItem>) {
    const categorizedItems = useMemo(
        () => groupByCategory(items, selectCategory),
        [items, selectCategory],
    );
    const noItems = items.length === 0;

    return (
        <div
            className="card-category-list"
            style={{ opacity: loading ? '.5' : '' }}
        >
            {noItems ? (
                <NoItems />
            ) : (
                Object.keys(categorizedItems).map((category) => (
                    <Category
                        key={category}
                        category={category}
                        items={categorizedItems[category]}
                    >
                        {children}
                    </Category>
                ))
            )}
        </div>
    );
}

CardCategoryList.Item = Item;

function Category<TItem>({ category, items, children }: CategoryProps<TItem>) {
    return (
        <div key={category} className="__container">
            <h6>{category}</h6>
            <div className="__item-container">
                {items.map((item) => children(item))}
            </div>
        </div>
    );
}

function Item({ name, description, actions, style }: ItemProps) {
    return (
        <div className="card-category-list-item" style={style}>
            <h5>{name}</h5>
            <div className="__description">{description}</div>
            <div className="__actions">{actions}</div>
        </div>
    );
}

function groupByCategory<TItem>(
    items: TItem[],
    selectCategory: (item: TItem) => string,
) {
    return items.reduce<CategorizedList<TItem>>((result, item) => {
        const category = selectCategory(item);
        const existing = result[category] || [];
        return {
            ...result,
            [category]: [...existing, item],
        };
    }, {});
}

type CardCategoryListProps<TItem> = Readonly<{
    items: TItem[];
    loading: boolean;
    selectCategory: (item: TItem) => string;
    children: (item: TItem) => React.ReactNode;
}>;

type CategoryProps<TItem> = Readonly<{
    category: string;
    items: TItem[];
    children: (item: TItem) => React.ReactNode;
}>;

type ItemProps = Readonly<{
    name: string;
    description: string;
    actions: React.ReactNode;
    style?: React.CSSProperties;
}>;

type CategorizedList<TItem> = Record<string, TItem[]>;
