import { Button, Search } from '@rf-smart-for-oraclecloud/ui';
import { MdOutlineViewColumn, MdFilterList } from 'react-icons/md';
import { useAppIntl } from '@@/intl';

export function ActionBar({
    onCreate,
    onSearch,
    onManageColumns,
    onManageFilters,
}: ActionBarProps) {
    const intl = useAppIntl();

    return (
        <>
            <div className="__action-group">
                <Search
                    aria-label={intl.profiles.searchProfiles()}
                    placeholder={intl.profiles.searchProfiles()}
                    onSubmit={onSearch}
                    onClear={() => onSearch('')}
                />
            </div>
            <div className="__action-group">
                <Button
                    className="__primary-action __save-action"
                    onClick={onCreate}
                >
                    {intl.profiles.createProfile()}
                </Button>
                <div className="__table-actions">
                    <Button
                        onClick={onManageColumns}
                        family="subtle"
                        variant="ghost"
                        icon={<MdOutlineViewColumn size="2rem" />}
                    />
                    <Button
                        onClick={onManageFilters}
                        family="subtle"
                        variant="ghost"
                        icon={<MdFilterList size="2rem" />}
                    />
                </div>
            </div>
        </>
    );
}

type ActionBarProps = Readonly<{
    onCreate: () => void;
    onSearch: (filter: string) => void;
    onManageColumns: () => void;
    onManageFilters: () => void;
}>;
