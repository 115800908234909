import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import { MdAdd } from 'react-icons/md';
import {
    Button,
    Cell,
    Column,
    Row,
    Search,
    Table,
    TableBody,
    TableHeader,
} from '@rf-smart-for-oraclecloud/ui';
import {
    NoItems,
    Pagination,
    UserGroup,
    pageItems,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { useAppIntl } from '@@/intl';
import { Section } from './Section';
import { ProfileForm } from '../types';
import { useUserGroupList } from './useUserGroupList';
import { filterUserGroup } from '../utils';

const pageSize = 10;

export function UserGroups() {
    const navigate = useNavigate();
    const intl = useAppIntl();
    const [query, setQuery] = useState({
        filter: '',
        page: 1,
    });
    const { loading, userGroups } = useUserGroupList();
    const assignedUserGroupsIds = useWatch<ProfileForm, 'userGroups'>({
        name: 'userGroups',
    });
    const items = useMemo(
        () =>
            assignedUserGroupsIds
                .map((id) => userGroups[id])
                .filter((u) => u !== undefined),
        [userGroups, assignedUserGroupsIds],
    );

    const filteredItems = useMemo(
        () =>
            pageItems({ items, ...query, pageSize, onFilter: filterUserGroup }),
        [items, query],
    );

    const handleAssign = () => navigate('./userGroups');
    const handleFilter = (filter: string) =>
        setQuery((q) => ({ ...q, filter }));
    const handlePage = (page: number) => setQuery((q) => ({ ...q, page }));

    return (
        <Section className="profile-detail-home-user-groups">
            <Section.Header title={intl.profiles.assignedUserGroups()}>
                <Button icon={<MdAdd />} variant="ghost" onClick={handleAssign}>
                    {intl.profiles.assign()}
                </Button>
            </Section.Header>
            <Section.ActionHeader>
                <Search
                    aria-label={intl.profiles.searchUserGroups()}
                    placeholder={intl.profiles.searchUserGroups()}
                    className="__search"
                    onSubmit={handleFilter}
                    onClear={() => handleFilter('')}
                />
            </Section.ActionHeader>
            <Section.Content className="">
                <Table
                    aria-label={intl.profiles.entries()}
                    selectionMode="multiple"
                >
                    <TableHeader>
                        <Column isRowHeader>{intl.common.name()}</Column>
                        <Column>{intl.common.description()}</Column>
                        <Column />
                    </TableHeader>
                    <TableBody renderEmptyState={() => <NoItems />}>
                        {!loading &&
                            filteredItems.items.map((userGroup) => (
                                <UserGroupRow
                                    key={userGroup.id}
                                    userGroup={userGroup}
                                />
                            ))}
                    </TableBody>
                </Table>
                <Pagination
                    pageSize={pageSize}
                    page={query.page}
                    totalItems={filteredItems.totalItems}
                    onChangePage={handlePage}
                    showPageSize={false}
                />
            </Section.Content>
        </Section>
    );
}

function UserGroupRow({ userGroup }: UserGroupRowProps) {
    return (
        <Row>
            <Cell>{userGroup.name}</Cell>
            <Cell>{userGroup.description}</Cell>
            <Cell />
        </Row>
    );
}

type UserGroupRowProps = Readonly<{
    userGroup: UserGroup;
}>;
