import { Text } from '@rf-smart-for-oraclecloud/ui';
import { WorkflowActivity } from '@rf-smart-for-oraclecloud/wf-engine';
import { AddActivityCard } from './ActivityCard';

export function FilteredActivities({ activities }: FilteredActivitiesProps) {
    return (
        <div className="wf-editor-add-activity__list">
            {Object.keys(activities).map((category) => {
                const categoryItems = activities[category];
                if (categoryItems.length === 0) {
                    return null;
                }

                return (
                    <div key={category} className="__category">
                        <Text elementType="h4">{category}</Text>
                        {categoryItems.map((activity) => (
                            <AddActivityCard
                                key={activity.type}
                                activity={activity}
                            />
                        ))}
                    </div>
                );
            })}
        </div>
    );
}

type FilteredActivitiesProps = Readonly<{
    activities: Record<string, WorkflowActivity[]>;
}>;
