import {
    MdDelete,
    MdOutlineSettings,
    MdOutlineElectricBolt,
} from 'react-icons/md';
import { IconButton } from '@rf-smart-for-oraclecloud/platform-ui';
import { ActivityIcon } from '@rf-smart-for-oraclecloud/wf-web';
import { useAppIntl } from '@@/intl';
import { ActivityViewProps } from './types';
import { getEditorMeta } from './utils';

export function ActivityViewHeader({
    spec,
    activity,
    onDelete,
    onEdit,
}: ActivityViewProps) {
    const intl = useAppIntl();
    const label = spec.name ?? activity.name;
    const editorMeta = getEditorMeta(activity);
    const icon =
        editorMeta?.icon === undefined
            ? MdOutlineElectricBolt
            : editorMeta.icon;

    return (
        <>
            <div className="wf-editor-activity-view__header__section">
                <ActivityIcon label={label} icon={icon} />
            </div>
            <div className="wf-editor-activity-view__header__section">
                <IconButton
                    toolTip={intl.common.delete()}
                    icon={<MdDelete />}
                    onClick={() => onDelete(spec.key)}
                />
                <IconButton
                    toolTip={intl.common.configure()}
                    icon={
                        <MdOutlineSettings onClick={() => onEdit(spec.key)} />
                    }
                />
            </div>
        </>
    );
}
