import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { WorkflowEditorStore } from '../types';

export const useWorkflowEditorStore = create(
    devtools(
        immer<WorkflowEditorStore>(() => ({
            display: 'design',
            workflow: {
                selection: undefined,
                activityMap: {
                    root: {
                        key: 'root',
                        id: 'root',
                        type: 'workflow',
                        name: 'root',
                        description: 'root',
                    },
                },
                activityTree: [
                    {
                        key: 'root',
                        children: {
                            default: [],
                        },
                    },
                ],
                activityNav: [{ key: 'root', childKey: 'default' }],
            },
        })),
    ),
);

export const set = useWorkflowEditorStore.setState;
export const get = useWorkflowEditorStore.getState;
