import { RouteObject } from 'react-router-dom';
import { ProfileList } from './ProfileList';
import { ProfileDetail } from './ProfileDetail/index';
import { ProfileDetailHome } from './ProfileDetail/Home';
import { ProfileDetailEntry } from './ProfileDetail/Entry';
import { ProfileDetailUserGroups } from './ProfileDetail/UserGroups';

export const profileRoutes: RouteObject[] = [
    {
        index: true,
        element: <ProfileList />,
    },
    {
        path: ':profileId',
        element: <ProfileDetail />,
        children: [
            {
                index: true,
                element: <ProfileDetailHome />,
            },
            {
                path: 'entry/:entryId',
                element: <ProfileDetailEntry />,
            },
            {
                path: 'userGroups',
                element: <ProfileDetailUserGroups />,
            },
        ],
    },
];
