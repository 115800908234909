import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WorkflowDefinition } from './WorkflowDefinition';
import { WorkflowHeader } from './WorkflowHeader';
import { useFetchWorkflow } from './useFetchWorkflow';
import { useUpdateWorkflow } from './useUpdateWorkflow';
import './styles.css';

export function Workflow() {
    const { workflowId } = useParams();
    const { workflow, loading } = useFetchWorkflow(workflowId!);
    const [wfTextDefinition, setWfTextDefinition] = useState('');
    const { updateWorkflow } = useUpdateWorkflow();

    useEffect(() => {
        if (workflow !== undefined) {
            setWfTextDefinition(
                JSON.stringify(workflow.latestRevision.data, null, 4),
            );
        }
    }, [workflow]);

    const handleSave = async () => {
        if (workflow !== undefined) {
            await updateWorkflow({
                id: workflow.id,
                category: workflow.category,
                name: workflow.name,
                description: workflow.description,
                version: workflow.latestRevision.version + 1,
                data: JSON.parse(wfTextDefinition),
            });
        }
    };

    if (!workflow || loading) {
        return '...Loading';
    }

    return (
        <div className="wf-editor-workflow">
            <div className="wf-editor-workflow__header">
                <WorkflowHeader onSave={handleSave} />
            </div>
            <div className="wf-editor-workflow__definition">
                <WorkflowDefinition
                    value={wfTextDefinition}
                    onChange={setWfTextDefinition}
                />
            </div>
        </div>
    );
}
