import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { BasicPage } from '@@/layout/BasicPage';
import { useAppIntl } from '@@/intl';
import { Details } from './Details';
import { Entries } from './Entries';
import { UserGroups } from './UserGroups';
import { ProfileDetailHomeActions } from './Actions';
import { ProfileForm } from '../types';
import './styles.css';

export function ProfileDetailHome() {
    const intl = useAppIntl();
    const profileName = useWatch<ProfileForm, 'name'>({ name: 'name' });
    const breadCrumb = useMemo(
        () => [{ name: intl.profiles.profiles(), nav: '..' }, profileName],
        [intl, profileName],
    );

    return (
        <BasicPage
            breadCrumb={breadCrumb}
            className="profile-detail-home"
            actionBar={<ProfileDetailHomeActions />}
        >
            <div className="__body">
                <div className="__left">
                    <Details />
                </div>
                <div className="__right">
                    <Entries />
                    <UserGroups />
                </div>
            </div>
        </BasicPage>
    );
}
