import { ActvityView } from '../../components/ActivityView';
import { useWorkflowEditorStore } from '../../store';
import {
    removeActivity,
    selectActivity,
    drillDownActivity,
} from '../../store/actions';
import { selectCurrentActivities } from '../../store/selectors';
import './styles.css';

export function ActivityList() {
    const activities = useWorkflowEditorStore(selectCurrentActivities);
    const selection = useWorkflowEditorStore(
        (state) => state.workflow.selection,
    );
    const selectedActivityId =
        selection?.type === 'activity' ? selection.id : undefined;

    return activities.map((spec, index) => (
        <ActvityView
            key={spec.key}
            spec={spec}
            index={index + 1}
            isSelected={selectedActivityId === spec.key}
            onDelete={removeActivity}
            onEdit={selectActivity}
            onDrillDown={drillDownActivity}
        />
    ));
}
