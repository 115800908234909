import { useController } from 'react-hook-form';
import {
    TextInput,
    TextArea,
    Select,
    RadioGroup,
    Radio,
} from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';
import { ProfileEntryForm } from '../types';
import { useWorkflowList } from '../useWorkflowList';

export function EntryNameField() {
    const intl = useAppIntl();
    const max = 50;
    const label = intl.common.name();
    const {
        field,
        formState: {
            errors: { name: error },
        },
    } = useController<ProfileEntryForm, 'name'>({
        name: 'name',
        rules: {
            required: intl.validation.required({ label }),
            maxLength: {
                value: max,
                message: intl.validation.maxLength({ max }),
            },
        },
    });

    return (
        <TextInput
            autoFocus
            isRequired
            label={label}
            errorMessage={error?.message}
            isInvalid={!!error}
            {...field}
        />
    );
}

export function EntryDescriptionField() {
    const intl = useAppIntl();
    const max = 500;
    const label = intl.common.description();
    const {
        field,
        formState: {
            errors: { description: error },
        },
    } = useController<ProfileEntryForm, 'description'>({
        name: 'description',
        rules: {
            required: intl.validation.required({ label }),
            maxLength: {
                value: max,
                message: intl.validation.maxLength({ max }),
            },
        },
    });

    return (
        <TextArea
            isRequired
            label={label}
            errorMessage={error?.message}
            isInvalid={!!error}
            {...field}
        />
    );
}

const workflowTextSelector = (wf: { name: string }) => wf.name;
const workflowValueSelector = (wf: { id: string }) => wf.id;

export function EntryWorkflowField() {
    const intl = useAppIntl();
    const label = intl.profiles.workflow();
    const { workflows, loading } = useWorkflowList();
    const {
        field,
        formState: {
            errors: { workflowId: error },
        },
    } = useController<ProfileEntryForm, 'workflowId'>({
        name: 'workflowId',
        rules: {
            required: intl.validation.required({ label }),
        },
    });
    const { ref, ...props } = field;

    return (
        <Select
            isRequired
            isDisabled={loading}
            label={label}
            items={Object.values(workflows)}
            textSelector={workflowTextSelector}
            valueSelector={workflowValueSelector}
            errorMessage={error?.message}
            isInvalid={!!error}
            {...props}
        />
    );
}

export function EntryWorkflowVersionField() {
    const intl = useAppIntl();
    const label = intl.profiles.status();
    const {
        field,
        formState: {
            errors: { version: error },
        },
    } = useController<ProfileEntryForm, 'version'>({
        name: 'version',
        rules: {
            required: intl.validation.required({ label }),
        },
    });
    const { ref, ...props } = field;

    return (
        <RadioGroup
            orientation="horizontal"
            isRequired
            label={label}
            errorMessage={error?.message}
            isInvalid={!!error}
            {...props}
        >
            <Radio value="Published">{intl.profiles.published()}</Radio>
            <Radio value="Latest">{intl.profiles.latest()}</Radio>
        </RadioGroup>
    );
}

export function EntryWorkflowConfigField() {
    const intl = useAppIntl();
    const label = intl.profiles.configuration();
    const {
        field,
        formState: {
            errors: { with: error },
        },
    } = useController<ProfileEntryForm, 'with'>({
        name: 'with',
        rules: {
            validate: {
                json: (v) =>
                    isJson(v) ? undefined : intl.validation.json({ label }),
            },
        },
    });

    return (
        <TextArea
            label={label}
            errorMessage={error?.message}
            isInvalid={!!error}
            {...field}
        />
    );
}

function isJson(value: string) {
    try {
        JSON.parse(value);
        return true;
    } catch {
        return false;
    }
}
