import { ProfileEntryForm, ProfileForm } from './types';

export const createProfile: () => ProfileForm = () => ({
    name: '',
    description: '',
    isActive: false,
    entries: {},
    userGroups: [],
});

export const createEntry: () => ProfileEntryForm = () => ({
    id: globalThis.crypto.randomUUID(),
    name: '',
    description: '',
    workflowId: '',
    version: 'Published',
    with: `{

        
}`,
});
