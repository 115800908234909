import { TextArea } from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';
import './styles.css';

export function WorkflowDefinitionManualEdit({
    onChange,
    value,
}: WorkflowDefinitionProps) {
    const intl = useAppIntl();

    return (
        <div className="wf-editor-definition-code">
            <TextArea
                onChange={onChange}
                value={value}
                aria-label={intl.config.tempManualEdit()}
            />
        </div>
    );
}

type WorkflowDefinitionProps = Readonly<{
    value: string;
    onChange: (value: string) => void;
}>;
