import { useAppIntl } from '@@/intl';
import { Search } from '@rf-smart-for-oraclecloud/ui';

export function ActivitySearch({ onSearch }: ActivitySearchProps) {
    const intl = useAppIntl();

    return (
        <div className="wf-editor-add-activity__search">
            <Search
                onSubmit={onSearch}
                onClear={() => onSearch('')}
                placeholder={intl.common.search()}
                aria-label={intl.common.search()}
            />
        </div>
    );
}

type ActivitySearchProps = Readonly<{
    onSearch: (value: string) => void;
}>;
