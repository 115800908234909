import { MdArrowForward, MdAdd } from 'react-icons/md';
import { IconButton } from '@rf-smart-for-oraclecloud/platform-ui';
import { ActivityPlaceHolder } from '@rf-smart-for-oraclecloud/wf-web';
import { useAppIntl } from '@@/intl';
import { selectAddActivity } from '../store/actions';
import { useWorkflowEditorStore } from '../store';

export function AddActivityButton({ index }: Readonly<{ index: number }>) {
    const intl = useAppIntl();
    const selection = useWorkflowEditorStore(
        (state) => state.workflow.selection,
    );
    const isSelected =
        selection?.type === 'addActivity' && selection.index === index;

    if (isSelected) {
        return (
            <ActivityPlaceHolder
                icon={MdArrowForward}
                label={intl.config.selectActivity()}
                highlight
            />
        );
    }

    return (
        <IconButton
            icon={<MdAdd size="2rem" />}
            toolTip={intl.config.addActivity()}
            onClick={() => selectAddActivity(index)}
        />
    );
}
