import { useEffect, useState } from 'react';
import { useToast } from '@rf-smart-for-oraclecloud/ui';
import { useProfilesApi } from '@@/api/profiles';
import { useAppIntl } from '@@/intl';
import { ProfileForm } from './types';
import { createProfile } from './defaults';
import { toProfileForm } from './utils';

export function useProfileDetail(profileId?: string) {
    const intl = useAppIntl();
    const toast = useToast();
    const [{ profile, loading }, setState] = useState<{
        profile?: ProfileForm;
        loading: boolean;
    }>({ profile: undefined, loading: false });
    const { get, listAssignments } = useProfilesApi();

    useEffect(() => {
        if (profileId === undefined) {
            return;
        }

        if (profileId === 'create') {
            setState((s) => ({ ...s, profile: createProfile() }));
            return;
        }

        setState((s) => ({ ...s, loading: true }));
        Promise.all([get(profileId), listAssignments(profileId)])
            .then(([profileDetail, assignedUserGroupsIds]) => {
                if (profileDetail !== undefined) {
                    setState((s) => ({
                        ...s,
                        profile: toProfileForm(
                            profileDetail,
                            assignedUserGroupsIds,
                        ),
                    }));
                }
            })
            .catch(() =>
                toast.showErrorToast(intl.profiles.profileFetchFailedToast()),
            )
            .finally(() => setState((s) => ({ ...s, loading: false })));
    }, [profileId, get, listAssignments, toast, intl]);

    return {
        profile,
        loading,
    };
}
