import { Page } from '@rf-smart-for-oraclecloud/ui';
import './style.css';
import { BreadCrumbProps, BreadCrumbs } from './BreadCrumbs';

export function BasicPage({
    breadCrumb,
    actionBar,
    children,
    className,
}: BasicPageProps) {
    const customCn = className !== undefined ? ` ${className}` : '';

    return (
        <Page className={`wf-page${customCn}`}>
            <Page.Header>
                <BreadCrumbs items={breadCrumb} />
            </Page.Header>
            <Page.Body>
                {actionBar && (
                    <Page.ActionBar className="wf-page-action-bar">
                        {actionBar}
                    </Page.ActionBar>
                )}
                {children}
            </Page.Body>
        </Page>
    );
}

type BasicPageProps = Readonly<
    React.PropsWithChildren<{
        breadCrumb: BreadCrumbProps['items'];
        actionBar?: React.ReactNode;
        className?: string;
    }>
>;
