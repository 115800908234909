import { BasicPage } from '@@/layout/BasicPage';
import { useAppIntl } from '@@/intl';

export function SessionAnalyzer() {
    const intl = useAppIntl();
    const breadCrumb = [intl.sessions.sessionAnalyzer()];

    return (
        <BasicPage breadCrumb={breadCrumb}>
            {intl.sessions.sessionAnalyzer()}
        </BasicPage>
    );
}
