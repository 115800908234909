import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
    Button,
    DropdownMenu,
    DropdownMenuItem,
    Key,
    Tag,
} from '@rf-smart-for-oraclecloud/ui';
import { MdArrowDropDown } from 'react-icons/md';
import { useAppIntl } from '@@/intl';
import { useProfileActions } from './useProfileActions';
import { ProfileForm } from '../types';
import { DeactivateModal } from '../../common/DeactivateModal';
import { DeleteModal } from '../../common/DeleteModal';

export function ProfileDetailHomeActions() {
    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const intl = useAppIntl();
    const { watch } = useFormContext<ProfileForm>();
    const [isActive, name] = watch(['isActive', 'name']);
    const {
        handleCancel,
        handleDelete,
        handleSave,
        handleSetActive,
        isCreate,
    } = useProfileActions();

    const handleActivate = handleSetActive(true);
    const handleStartDeactivate = () => setDeactivateModalOpen(true);
    const handleCancelDeactivate = () => setDeactivateModalOpen(false);
    const handleSubmitDeactivate = () => {
        handleSetActive(false)();
        setDeactivateModalOpen(false);
    };
    const handleStartDelete = () => setDeleteModalOpen(true);
    const handleCancelDelete = () => setDeleteModalOpen(false);
    const handleSubmitDelete = () => {
        handleDelete();
        setDeleteModalOpen(false);
    };
    const handleMenu = (key: Key) => {
        const actionMap = {
            activate: handleActivate,
            deactivate: handleStartDeactivate,
            delete: handleStartDelete,
        };
        actionMap[key as AdditionalActions]();
    };

    return (
        <>
            <div className="__action-group">
                {!isCreate && (
                    <>
                        <div className="__actions-wrapper">
                            <DropdownMenu
                                onAction={handleMenu}
                                ariaLabel={intl.profiles.actions()}
                                label={intl.profiles.actions()}
                                icon={<MdArrowDropDown />}
                                variant="outline"
                            >
                                {isActive && (
                                    <DropdownMenuItem id="deactivate">
                                        {intl.profiles.deactivate()}
                                    </DropdownMenuItem>
                                )}
                                {!isActive && (
                                    <>
                                        <DropdownMenuItem id="activate">
                                            {intl.profiles.activate()}
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            id="delete"
                                            family="danger"
                                        >
                                            {intl.common.delete()}
                                        </DropdownMenuItem>
                                    </>
                                )}
                            </DropdownMenu>
                        </div>
                        <Tag
                            family={isActive ? 'positive' : 'subtle'}
                            style={{ width: 'fit-content' }}
                        >
                            {intl.profiles.isActiveText({ isActive })}
                        </Tag>
                    </>
                )}
            </div>
            <div className="__action-group">
                <Button variant="ghost" family="subtle" onClick={handleCancel}>
                    {intl.common.cancel()}
                </Button>
                <Button
                    variant="solid"
                    className="__primary-action __save-action"
                    onClick={handleSave}
                >
                    {intl.common.save()}
                </Button>
            </div>
            <DeactivateModal
                profileName={name}
                isOpen={deactivateModalOpen}
                onClose={handleCancelDeactivate}
                onSubmit={handleSubmitDeactivate}
            />
            <DeleteModal
                profileName={name}
                isOpen={deleteModalOpen}
                onClose={handleCancelDelete}
                onSubmit={handleSubmitDelete}
            />
        </>
    );
}

type AdditionalActions = 'activate' | 'deactivate' | 'delete';
