import { useState, useCallback } from 'react';
import { useToast } from '@rf-smart-for-oraclecloud/ui';
import { useWorkflowsApi } from '@@/api/workflows';
import { UpdateWorkflowRequest } from '@@/types';
import { useAppIntl } from '@@/intl';

export function useUpdateWorkflow() {
    const intl = useAppIntl();
    const toast = useToast();
    const [data, setData] = useState<UpdateWorkflowState>({
        loading: false,
    });
    const { update } = useWorkflowsApi();

    const updateWorkflow = useCallback(
        (request: UpdateWorkflowRequest) => {
            setData({ loading: true });
            return update(request)
                .then(() =>
                    toast.showSuccessToast(intl.profiles.workflowsSaveToast()),
                )
                .catch(() =>
                    toast.showErrorToast(
                        intl.profiles.workflowsSaveFailedToast(),
                    ),
                )
                .finally(() => setData((s) => ({ ...s, loading: false })));
        },
        [update, intl, toast],
    );

    return {
        updateWorkflow,
        ...data,
    };
}

type UpdateWorkflowState = {
    loading: boolean;
};
