import { useEffect } from 'react';
import { create } from 'zustand';
import { UserGroup } from '@rf-smart-for-oraclecloud/platform-ui';
import { useUserGroupsApi } from '@@/api/userGroups';
import { PagedResponse } from '@@/types';
import { useAppIntl } from '@@/intl';
import { useToast } from '@rf-smart-for-oraclecloud/ui';

const useUserGroupsListStore = create<{
    initialized: boolean;
    loading: boolean;
    userGroups: Record<string, UserGroup>;
}>(() => ({
    initialized: false,
    loading: false,
    userGroups: {},
}));

const { setState: set } = useUserGroupsListStore;

export function useUserGroupList(): {
    loading: boolean;
    userGroups: Record<string, UserGroup>;
} {
    const intl = useAppIntl();
    const toast = useToast();
    const { list } = useUserGroupsApi();
    const { initialized, loading, userGroups } = useUserGroupsListStore();

    useEffect(() => {
        if (!initialized) {
            set({ loading: true });
            list({ filter: '', page: 1, pageSize: 9999 })
                .then(transformUserGroups)
                .then((result) =>
                    set({ userGroups: result, initialized: true }),
                )
                .catch(() =>
                    toast.showErrorToast(intl.profiles.userGroupsFetchFailed()),
                )
                .finally(() => set({ loading: false }));
        }
    }, [initialized, list, toast, intl]);

    return {
        loading,
        userGroups,
    };
}

function transformUserGroups(
    data: PagedResponse<UserGroup>,
): Record<string, UserGroup> {
    return data.items.reduce(
        (result, u) => ({
            ...result,
            [u.id]: u,
        }),
        {},
    );
}
