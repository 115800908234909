import {
    MdLan,
    MdMoreVert,
    MdEdit,
    MdContentCopy,
    MdDeleteOutline,
} from 'react-icons/md';
import {
    Button,
    DropdownMenu,
    DropdownMenuItem,
    Text,
    Key,
} from '@rf-smart-for-oraclecloud/ui';
import { WorkflowListItem } from '@@/types';
import { useAppIntl } from '@@/intl';

export function FilteredWorkflowListItem({
    workflow,
    onDuplicate,
    onDelete,
    onEdit,
}: FilteredWorkflowListItemProps) {
    const intl = useAppIntl();
    const { created, createdBy, name } = workflow;

    const handleEdit = () => onEdit(workflow);

    const handleMenuAction = (key: Key) => {
        if (key === 'duplicate') {
            onDuplicate(workflow);
        } else if (key === 'delete') {
            onDelete(workflow);
        }
    };

    return (
        <div className="wf-list-item">
            <div className="wf-list-item__section">
                <div className="__header-left">
                    <MdLan size="2rem" />
                    <Text elementType="h4">{name}</Text>
                </div>
                <DropdownMenu
                    className="__item-action-menu"
                    onAction={handleMenuAction}
                    ariaLabel={intl.common.actions()}
                    icon={<MdMoreVert size="2rem" />}
                >
                    <DropdownMenuItem id="duplicate">
                        <MdContentCopy size="2rem" />
                        {intl.common.duplicate()}
                    </DropdownMenuItem>
                    <DropdownMenuItem id="delete" family="danger">
                        <MdDeleteOutline size="2rem" />
                        {intl.common.delete()}
                    </DropdownMenuItem>
                </DropdownMenu>
            </div>
            <div className="wf-list-item__section">
                <div>{intl.common.createdOn()}</div>
                <div>{created.toLocaleDateString()}</div>
            </div>
            <div className="wf-list-item__section">
                <div>{intl.common.createdBy()}</div>
                <div>{createdBy}</div>
            </div>
            <Button
                icon={<MdEdit />}
                family="subtle"
                type="button"
                onClick={handleEdit}
            >
                {intl.common.edit()}
            </Button>
        </div>
    );
}

type FilteredWorkflowListItemProps = Readonly<{
    workflow: WorkflowListItem;
    onEdit: (item: WorkflowListItem) => void;
    onDuplicate: (item: WorkflowListItem) => void;
    onDelete: (item: WorkflowListItem) => void;
}>;
