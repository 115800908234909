import { useWorkflowEditorStore } from '../store';
import { WorkflowSettings } from './screens/WorkflowSettings';
import { ActivitySettings } from './screens/ActivitySettings';
import { selectSelectedActivity } from '../store/selectors';
import { AddActivitySettings } from './screens/AddActivity';

export function Settings() {
    const selection = useWorkflowEditorStore(
        (state) => state.workflow.selection,
    );
    const spec = useWorkflowEditorStore(selectSelectedActivity);

    if (selection === undefined) {
        return null;
    }

    if (selection.type === 'workflow') {
        return <WorkflowSettings />;
    }

    if (selection.type === 'addActivity') {
        return <AddActivitySettings />;
    }

    if (selection.type === 'activity') {
        return <ActivitySettings spec={spec!} />;
    }
}
