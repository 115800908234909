import { useEffect, useMemo, useState } from 'react';
import { useAppIntl } from '@@/intl';
import { columnDefinitions } from './columns';
import { ProfilesListEditableColumnType } from './types';
import {
    saveColumns,
    useProfilesListViewSettingsStore,
} from './useProfilesListViewSettingsStore';
import { useProfilesListState } from './useProfilesListState';

export function useProfilesListViewColumnSettings() {
    const intl = useAppIntl();
    const {
        modalStatus: { columnsOpen },
    } = useProfilesListState();
    const columns = useProfilesListViewSettingsStore((s) => s.columns);
    const [selectedColumns, setSelectedColumns] = useState(columns);

    useEffect(() => {
        setSelectedColumns(columns);
    }, [columns, columnsOpen]);

    const columnOptions = useMemo<
        { key: ProfilesListEditableColumnType; label: string }[]
    >(() => {
        const notSelected = Object.keys(columnDefinitions).filter(
            (c) =>
                !selectedColumns.includes(c as ProfilesListEditableColumnType),
        ) as ProfilesListEditableColumnType[];

        return [...selectedColumns, ...notSelected]
            .filter((c) => columnDefinitions[c] !== undefined)
            .map((key) => ({
                key,
                label: columnDefinitions[key].label({ intl }),
            }));
    }, [selectedColumns, intl]);

    const saveColumnSettings = () => {
        saveColumns(selectedColumns);
    };

    return {
        columnOptions,
        selectedColumns,
        setSelectedColumns,
        saveColumnSettings,
    };
}
