import { Navigate, RouteObject } from 'react-router-dom';
import { AppLayout } from './layout/AppLayout';
import { configRoutes } from './areas/config/routes';
import { profileRoutes } from './areas/profiles/routes';
import { sessionRoutes } from './areas/sessions/routes';
import { settingsRoutes } from './areas/settings/routes';

export const routes: RouteObject[] = [
    {
        Component: AppLayout,
        children: [
            {
                index: true,
                element: <Navigate to="/config" replace />,
            },
            {
                path: '/config',
                children: configRoutes,
            },
            {
                path: 'profiles',
                children: profileRoutes,
            },
            {
                path: '/sessions',
                children: sessionRoutes,
            },
            {
                path: '/settings',
                children: settingsRoutes,
            },
        ],
    },
];
