import { Search } from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';
import { CreateWorkflowButton } from './CreateWorkflowButton';
import { useWorkflowListStore } from './store';

export function WorkflowListActionBar({ refreshWorkflows }: ActionsProps) {
    const intl = useAppIntl();
    const setFilter = useWorkflowListStore((state) => state.setListFilter);

    const handleFilterSubmit = setFilter;
    const handleFilterClear = () => setFilter('');

    return (
        <>
            <Search
                onSubmit={handleFilterSubmit}
                onClear={handleFilterClear}
                placeholder={intl.common.search()}
                aria-label={intl.common.search()}
            />
            <CreateWorkflowButton refeshWorkflows={refreshWorkflows} />
        </>
    );
}

type ActionsProps = Readonly<{
    refreshWorkflows: () => void;
}>;
