import { TextArea } from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';
import { useWorkflowEditorStore } from '../../store';
import './styles.css';

export function WorkflowDefinitionCodeBehind() {
    const { activityMap, activityTree } = useWorkflowEditorStore(
        (state) => state.workflow,
    );
    const intl = useAppIntl();

    return (
        <div className="wf-editor-definition-code">
            <TextArea
                value={JSON.stringify({ activityTree, activityMap }, null, 4)}
                aria-label={intl.config.codeBehind()}
            />
        </div>
    );
}
