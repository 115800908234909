export function useWorkflowTemplates() {
    return {
        templates: [
            { name: 'template1', value: 't1' },
            { name: 'template2', value: 't2' },
            { name: 'template3', value: 't3' },
        ],
        existing: [
            { name: 'existing1', value: 'e1' },
            { name: 'existing2', value: 'e2' },
            { name: 'existing3', value: 'e3' },
        ],
    };
}
