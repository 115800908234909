import {
    ApiUrl,
    ListRequest,
    UserGroup,
    defineApi,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { PagedResponse } from '@@/types';

export const useUserGroupsApi = defineApi((api) => ({
    list: ({ filter, page, pageSize }: ListRequest) =>
        api.get<PagedResponse<UserGroup>>(
            `userGroups?filter=${filter ?? ''}&page=${page ?? 1}&size=${pageSize ?? 20}`,
            {
                api: ApiUrl.Accounts,
            },
        ),
}));
