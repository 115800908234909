import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {
    ProfilesListEditableColumnType,
    ProfilesListViewSettingsStore,
} from './types';

export const useProfilesListViewSettingsStore = create(
    persist<ProfilesListViewSettingsStore>(
        () => ({
            columns: ['description', 'entries', 'isActive'],
            filters: {
                status: 'all',
            },
        }),
        { name: 'profilesListViewSettings', version: 2 },
    ),
);

const { setState } = useProfilesListViewSettingsStore;

export const saveColumns = (columns: ProfilesListEditableColumnType[]) =>
    setState(() => ({
        columns,
    }));

export const saveFilters = (
    filters: Partial<ProfilesListViewSettingsStore['filters']>,
) =>
    setState((s) => ({
        filters: {
            ...s.filters,
            ...filters,
        },
    }));
