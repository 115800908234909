import { useNavigate } from 'react-router-dom';
import { CardCategoryList } from '@@/components/CardCategoryList';
import { WorkflowListItem } from '@@/types';
import { useWorkflowsApi } from '@@/api/workflows';
import { FilteredWorkflowListItem } from './FilteredWorkflowListItem';

export function FilteredWorkflowList({
    workflows,
    loading,
    refreshWorkflows,
}: FilteredWorkflowListProps) {
    const navigate = useNavigate();
    const { remove } = useWorkflowsApi();

    const handleEdit = (wf: WorkflowListItem) => navigate(`./${wf.id}`);

    const handleDelete = async (wf: WorkflowListItem) => {
        await remove(wf.id);
        refreshWorkflows();
    };

    const handleDuuplicate = (wf: WorkflowListItem) => {
        // eslint-disable-next-line no-console
        console.log('duplicate', wf);
    };

    return (
        <CardCategoryList
            items={workflows}
            loading={loading}
            selectCategory={(item) => item.category}
        >
            {(item) => (
                <FilteredWorkflowListItem
                    key={item.id}
                    workflow={item}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onDuplicate={handleDuuplicate}
                />
            )}
        </CardCategoryList>
    );
}

type FilteredWorkflowListProps = Readonly<{
    refreshWorkflows: () => void;
    workflows: WorkflowListItem[];
    loading: boolean;
}>;
