import { Profile, defineApi } from '@rf-smart-for-oraclecloud/platform-ui';
import { ProfilesListQuery } from '@@/areas/profiles/ProfileList/types';
import { CreateProfileRequest, PagedResponse } from '../types';

export const useProfilesApi = defineApi((api) => ({
    list: ({ refreshKey, ...query }: ProfilesListQuery) => {
        const { direction, filterColumns, ...queryArgs } = query;

        const queryStringValues = [
            ...Object.entries({
                ...queryArgs,
                orderDesc: direction === 'ascending',
            }).map(([key, value]) => ({ key, value })),
            ...filterColumns.map((fc) => ({ key: 'filterColumns', value: fc })),
        ];

        const queryString = queryStringValues
            .map(({ key, value }) => `${key}=${value}`)
            .join('&');

        return api.get<PagedResponse<Profile>>(`profiles?${queryString}`);
    },

    get: (id: string) => api.get<Profile | undefined>(`profiles/${id}`),

    create: (profile: CreateProfileRequest) =>
        api.post<Profile>('profiles', profile),

    update: (profile: Profile) =>
        api.put<Profile>(`profiles/${profile.id}`, profile),

    remove: (id: string) => api.delete(`profiles/${id}`),

    listAssignments: (profileId: string) =>
        api.get<string[]>(`profiles/${profileId}/assign`),

    updateAssignments: (profileId: string, assignedUserGroups: string[]) =>
        api.post<string[]>(`profiles/${profileId}/assign`, assignedUserGroups),
}));

export type ProfilesApi = ReturnType<typeof useProfilesApi>;
