import { create } from 'zustand';

export const useWorkflowListStore = create<WorkflowListStore>((set) => ({
    list: {
        filter: '',
    },
    setListFilter: (filter) =>
        set((state) => ({
            list: { ...state.list, filter },
        })),
}));

interface WorkflowListStore {
    list: {
        filter: string;
    };
    setListFilter: (filter: string) => void;
}
