import {
    Breadcrumbs,
    BreadcrumbsItem,
    Key,
} from '@rf-smart-for-oraclecloud/ui';
import { useNavigate } from 'react-router-dom';

export function BreadCrumbs({ items }: BreadCrumbProps) {
    const navigate = useNavigate();
    const handleNav = (key: Key) => {
        if (typeof key === 'string') {
            navigate(key);
        }
    };

    return (
        <Breadcrumbs onAction={handleNav}>
            {items.map((b) =>
                typeof b === 'string' ? (
                    <BreadcrumbsItem key={b}>{b}</BreadcrumbsItem>
                ) : (
                    <BreadcrumbsItem key={b.nav} id={b.nav}>
                        {b.name}
                    </BreadcrumbsItem>
                ),
            )}
        </Breadcrumbs>
    );
}

export type BreadCrumbProps = Readonly<{
    items: (
        | {
              name: string;
              nav: string;
          }
        | string
    )[];
}>;
