import { useState, useEffect } from 'react';
import {
    saveFilters,
    useProfilesListViewSettingsStore,
} from './useProfilesListViewSettingsStore';
import { useProfilesListState } from './useProfilesListState';

export function useProfilesListViewFilterSettings() {
    const {
        modalStatus: { filtersOpen },
    } = useProfilesListState();
    const {
        filters: { status },
    } = useProfilesListViewSettingsStore();
    const [filters, setFilters] = useState({
        status,
    });

    useEffect(() => {
        setFilters({ status });
    }, [status, filtersOpen]);

    const handlSaveFilters = () => {
        saveFilters({
            status: filters.status,
        });
    };

    return {
        filters,
        setFilters,
        saveFilters: handlSaveFilters,
    };
}
