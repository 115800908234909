import { Button } from '@rf-smart-for-oraclecloud/ui';
import { Dialog, Modal } from '@rf-smart-for-oraclecloud/platform-ui';
import { BasicModalLayout } from '@@/layout/BasicModal';
import { useAppIntl } from '@@/intl';

export function DeactivateModal({
    profileName,
    isOpen,
    onClose,
    onSubmit,
}: DeactivateModalProps) {
    const intl = useAppIntl();

    return (
        <Modal isOpen={isOpen}>
            <Dialog aria-label={intl.profiles.deactivate()}>
                <BasicModalLayout
                    size="small"
                    onClose={onClose}
                    actions={
                        <>
                            <Button variant="ghost" onClick={onClose}>
                                {intl.common.cancel()}
                            </Button>
                            <Button onClick={onSubmit}>
                                {intl.common.yes()}
                            </Button>
                        </>
                    }
                    title={`${intl.profiles.deactivateTitle({ name: profileName })}`}
                >
                    {intl.profiles.deactivateWarning()}
                </BasicModalLayout>
            </Dialog>
        </Modal>
    );
}

type DeactivateModalProps = Readonly<{
    profileName: string;
    isOpen: boolean;
    onClose: () => void;
    onSubmit: () => void;
}>;
