import { useEffect, useState, useMemo } from 'react';
import { useController, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
    ItemMover,
    ItemMoverColumn,
    UserGroup,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { useAppIntl } from '@@/intl';
import { BasicPage } from '@@/layout/BasicPage';
import { ProfileDetailUserGroupActions } from './Actions';
import { useUserGroupList } from '../Home/useUserGroupList';
import { ProfileForm } from '../types';
import { filterUserGroup } from '../utils';
import './styles.css';

export function ProfileDetailUserGroups() {
    const intl = useAppIntl();
    const navigate = useNavigate();
    const profileName = useWatch<ProfileForm, 'name'>({
        name: 'name',
    });
    const {
        field: { value, onChange },
    } = useController<ProfileForm, 'userGroups'>({
        name: 'userGroups',
    });
    const breadCrumb = useMemo(
        () => [
            { name: intl.profiles.profiles(), nav: '../..' },
            { name: profileName, nav: '..' },
            intl.profiles.userGroups(),
        ],
        [intl, profileName],
    );

    const columns = getColumns({ intl });

    const [assigned, setAssigned] = useState(value);
    useEffect(() => setAssigned(value), [value]);

    const { userGroups: allUserGroups, loading: userGroupsLoading } =
        useUserGroupList();

    const userGroups = useMemo(
        () => ({
            assigned: assigned
                .map((userGroupId) => allUserGroups[userGroupId])
                .filter((u) => u !== undefined),
            unassigned: Object.values(allUserGroups).filter(
                (userGroup) => !assigned.includes(userGroup.id),
            ),
        }),
        [assigned, allUserGroups],
    );

    const handleSubmit = () => {
        onChange(assigned);
        navigate('..');
    };

    const handleCancel = () => navigate('..');

    return (
        <BasicPage
            breadCrumb={breadCrumb}
            className="profile-detail-user-groups"
            actionBar={
                <ProfileDetailUserGroupActions
                    onSubmit={handleSubmit}
                    onCancel={handleCancel}
                />
            }
        >
            <div className="__content">
                {!userGroupsLoading && (
                    <ItemMover
                        columns={columns}
                        availableItems={userGroups.unassigned}
                        availableLabel={intl.profiles.availableUserGroups()}
                        availableDescription={intl.profiles.availableUserGroupsDescription()}
                        selectedItems={userGroups.assigned}
                        selectedLabel={intl.profiles.assignedUserGroups()}
                        selectedDescription={intl.profiles.assignedUserGroupsDescription()}
                        selectId={(u) => u.id}
                        onChange={setAssigned}
                        filter={filterUserGroup}
                    />
                )}
            </div>
        </BasicPage>
    );
}

function getColumns({
    intl,
}: {
    intl: ReturnType<typeof useAppIntl>;
}): ItemMoverColumn<UserGroup>[] {
    return [
        {
            label: intl.common.name(),
            render: (user: UserGroup) => user.name,
        },
        {
            label: intl.common.description(),
            render: (user: UserGroup) => user.description,
        },
    ];
}
