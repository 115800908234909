import { useController } from 'react-hook-form';
import { TextInput, TextArea } from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';
import { ProfileForm } from '../types';

export function ProfileNameField() {
    const intl = useAppIntl();
    const label = intl.common.name();
    const max = 100;
    const {
        field,
        formState: {
            errors: { name: error },
        },
    } = useController<ProfileForm, 'name'>({
        name: 'name',
        rules: {
            required: intl.validation.required({ label }),
            maxLength: {
                value: max,
                message: intl.validation.maxLength({ max }),
            },
        },
    });

    return (
        <TextInput
            isRequired
            autoFocus
            label={label}
            errorMessage={error?.message}
            isInvalid={!!error}
            {...field}
        />
    );
}

export function ProfileDescriptionField() {
    const intl = useAppIntl();
    const label = intl.common.description();
    const max = 500;
    const {
        field,
        formState: {
            errors: { description: error },
        },
    } = useController<ProfileForm, 'description'>({
        name: 'description',
        rules: {
            required: intl.validation.required({ label }),
            maxLength: {
                value: max,
                message: intl.validation.maxLength({ max }),
            },
        },
    });

    return (
        <TextArea
            isRequired
            label={label}
            errorMessage={error?.message}
            isInvalid={!!error}
            {...field}
        />
    );
}
