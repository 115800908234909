import { useEffect } from 'react';
import { create } from 'zustand';
import { useToast } from '@rf-smart-for-oraclecloud/ui';
import { useWorkflowsApi } from '@@/api/workflows';
import { PagedResponse, WorkflowListItem } from '@@/types';
import { useAppIntl } from '@@/intl';

const useWorkflowsListStore = create<{
    initialized: boolean;
    loading: boolean;
    workflows: Record<string, WorkflowListItem>;
}>(() => ({
    initialized: false,
    loading: false,
    workflows: {},
}));

const { setState: set } = useWorkflowsListStore;

export function useWorkflowList() {
    const toast = useToast();
    const intl = useAppIntl();
    const { list } = useWorkflowsApi();
    const { initialized, loading, workflows } = useWorkflowsListStore();

    useEffect(() => {
        if (!initialized) {
            set({ loading: true });
            list({ filter: '', page: 1, pageSize: 9999 })
                .then(transformWorkflows)
                .then((result) => set({ workflows: result, initialized: true }))
                .catch(() =>
                    toast.showErrorToast(intl.profiles.workflowsFetchFailed()),
                )
                .finally(() => set({ loading: false }));
        }
    }, [initialized, list, toast, intl]);

    return {
        loading,
        workflows,
    };
}

function transformWorkflows(
    data: PagedResponse<WorkflowListItem>,
): Record<string, WorkflowListItem> {
    return data.items.reduce(
        (result, wf) => ({
            ...result,
            [wf.id]: wf,
        }),
        {},
    );
}
