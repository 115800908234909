import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWatch } from 'react-hook-form';
import { MdAdd, MdOutlineRemoveRedEye } from 'react-icons/md';
import {
    Button,
    Cell,
    Column,
    Row,
    Table,
    TableBody,
    TableHeader,
    Tag,
} from '@rf-smart-for-oraclecloud/ui';
import {
    NoItems,
    Pagination,
    pageItems,
} from '@rf-smart-for-oraclecloud/platform-ui';
import { WorkflowListItem } from '@@/types';
import { useAppIntl } from '@@/intl';
import { Section } from './Section';
import { ProfileForm, ProfileEntryForm } from '../types';
import { useWorkflowList } from '../useWorkflowList';

const pageSize = 10;

export function Entries() {
    const navigate = useNavigate();
    const intl = useAppIntl();
    const [page, setPage] = useState(1);
    const { workflows } = useWorkflowList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const entries = useWatch<ProfileForm, 'entries'>({ name: 'entries' }) ?? {};

    const filteredItems = useMemo(
        () =>
            pageItems({
                items: Object.values(entries).map((entry) => ({
                    ...entry,
                    workflow: workflows[entry.workflowId],
                })),
                filter: '',
                page,
                pageSize,
                onFilter: () => true,
            }),
        [entries, page, workflows],
    );

    const handleAddEntry = () => {
        navigate(`./entry/create`);
    };

    return (
        <Section className="profile-detail-home-entries">
            <Section.Header title={intl.profiles.entries()}>
                <Button
                    icon={<MdAdd />}
                    variant="ghost"
                    onClick={handleAddEntry}
                >
                    {intl.profiles.addEntry()}
                </Button>
            </Section.Header>
            <Section.Content>
                <Table aria-label={intl.profiles.entries()}>
                    <TableHeader>
                        <Column isRowHeader>{intl.common.name()}</Column>
                        <Column>{intl.profiles.workflow()}</Column>
                        <Column>{intl.profiles.version()}</Column>
                        <Column />
                    </TableHeader>
                    <TableBody renderEmptyState={() => <NoItems />}>
                        {filteredItems.items.map((entry) => (
                            <EntryRow key={entry.id} entry={entry} />
                        ))}
                    </TableBody>
                </Table>
                <Pagination
                    pageSize={pageSize}
                    page={page}
                    totalItems={filteredItems.totalItems}
                    onChangePage={setPage}
                    showPageSize={false}
                />
            </Section.Content>
        </Section>
    );
}

function EntryRow({ entry }: EntryRowProps) {
    const intl = useAppIntl();
    const navigate = useNavigate();

    const handleEdit = () => navigate(`./entry/${entry.id}`);

    return (
        <Row>
            <Cell>{entry.name}</Cell>
            <Cell>{entry.workflow?.name}</Cell>
            <Cell>
                <Tag className="__status" family="brand" accent="secondary">
                    {intl.profiles.entryVersionText({
                        version: entry.version,
                    })}
                </Tag>
            </Cell>
            <Cell>
                <Button
                    onClick={handleEdit}
                    family="subtle"
                    variant="ghost"
                    icon={<MdOutlineRemoveRedEye size="2rem" />}
                />
            </Cell>
        </Row>
    );
}

type EntryRowProps = Readonly<{
    entry: ProfileEntryForm & {
        workflow?: WorkflowListItem;
    };
}>;
