import { MdAdd } from 'react-icons/md';
import { useAppIntl } from '@@/intl';
import { OpenModalButton } from '@@/components/OpenModalButton';
import { CreateWorkflowForm } from './CreateWorkflowForm';

export function CreateWorkflowButton({
    refeshWorkflows,
}: CreateWorkflowButtonProps) {
    const intl = useAppIntl();

    return (
        <OpenModalButton label={intl.common.create()} icon={<MdAdd />}>
            {(close) => (
                <CreateWorkflowForm
                    refreshWorkflows={refeshWorkflows}
                    onClose={close}
                />
            )}
        </OpenModalButton>
    );
}

type CreateWorkflowButtonProps = Readonly<{
    refeshWorkflows: () => void;
}>;
