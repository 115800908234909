import { useWorkflowEditorStore } from '../../store';
import { drillDownActivity } from '../../store/actions';
import { selectDesignerContext } from '../../store/selectors';
import { ActivityViewProps } from './types';
import { getEditorMeta } from './utils';

export function ActivityViewBody({ spec, activity }: ActivityViewProps) {
    const editorMeta = getEditorMeta(activity);
    const designerContext = useWorkflowEditorStore(selectDesignerContext);

    if (editorMeta?.designer === undefined) {
        return activity.description;
    }

    return (
        <editorMeta.designer
            activityKey={spec.key}
            designerContext={designerContext}
            onDrillDown={drillDownActivity}
        />
    );
}
