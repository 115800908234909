import {
    Button,
    SortableList,
    SlidingPanel,
    Key,
} from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';
import { useProfilesListViewColumnSettings } from './useProfilesListViewColumnSettings';

export function ColumnManager({ isOpen, onOpenChange }: ColumnManagerProps) {
    const intl = useAppIntl();
    const {
        columnOptions,
        selectedColumns,
        setSelectedColumns,
        saveColumnSettings,
    } = useProfilesListViewColumnSettings();

    const handleSave = () => {
        saveColumnSettings();
        onOpenChange(false);
    };

    const handleChangeColumns = (values: Iterable<Key>) => {
        if (Array.isArray(values)) {
            setSelectedColumns(values);
        }
    };

    return (
        <SlidingPanel
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={intl.common.manageColumns()}
        >
            <SlidingPanel.Body>
                <SortableList
                    allowsSelection
                    items={columnOptions}
                    onChange={handleChangeColumns}
                    value={selectedColumns}
                    textSelector={(option) => option.label}
                    valueSelector={(option) => option.key}
                />
            </SlidingPanel.Body>
            <SlidingPanel.Footer>
                <Button onClick={() => onOpenChange(false)} variant="ghost">
                    {intl.common.cancel()}
                </Button>
                <Button onClick={handleSave}>{intl.common.save()}</Button>
            </SlidingPanel.Footer>
        </SlidingPanel>
    );
}

type ColumnManagerProps = Readonly<{
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
}>;
