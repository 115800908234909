import { WorkflowDefinitionDesigner } from './Designer';
import { useWorkflowEditorStore } from '../../store';
import { WorkflowDefinitionCodeBehind } from './CodeBehind';
import { WorkflowDefinitionManualEdit } from './ManualEdit';
import './styles.css';

export function WorkflowDefinition({
    onChange,
    value,
}: WorkflowDefinitionProps) {
    const display = useWorkflowEditorStore((s) => s.display);

    return (
        <>
            {display === 'design' && <WorkflowDefinitionDesigner />}
            {display === 'code' && <WorkflowDefinitionCodeBehind />}
            {display === 'temp' && (
                <WorkflowDefinitionManualEdit
                    onChange={onChange}
                    value={value}
                />
            )}
        </>
    );
}

type WorkflowDefinitionProps = Readonly<{
    value: string;
    onChange: (value: string) => void;
}>;
