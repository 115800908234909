import { useMemo } from 'react';
import { create } from 'zustand';
import {
    ProfilesListStore,
    ProfilesListViewSettingsStoreFilters,
    SearchableColumns,
} from './types';
import { useProfilesListViewSettingsStore } from './useProfilesListViewSettingsStore';

const useProfilesListStore = create<ProfilesListStore>(() => ({
    query: {
        page: 1,
        size: 10,
        filter: '',
        orderBy: 'created',
        direction: 'descending',
        refreshKey: {},
    },
    data: {
        loading: false,
        items: [],
        totalItems: 0,
    },
    modalStatus: {
        columnsOpen: false,
        filtersOpen: false,
    },
}));

const searchableColumns = ['name', 'description'];

export const useProfilesListState = () => {
    const storeData = useProfilesListStore();
    const { columns, filters } = useProfilesListViewSettingsStore();

    const query = useMemo(() => {
        const visibleColumns = ['name', ...columns];
        const filterColumns = visibleColumns.filter((c) =>
            searchableColumns.includes(c),
        ) as SearchableColumns[];

        return {
            ...storeData.query,
            ...filters,
            filterColumns,
        };
    }, [storeData.query, filters, columns]);

    return {
        ...storeData,
        query,
    };
};

const { setState } = useProfilesListStore;

export const setQuery = ({
    status,
    ...value
}: Partial<
    ProfilesListStore['query'] & ProfilesListViewSettingsStoreFilters
>) => {
    setState(({ query }) => ({
        query: {
            ...query,
            ...value,
        },
    }));
};

export const setData = (value: Partial<ProfilesListStore['data']>) =>
    setState(({ data }) => ({
        data: {
            ...data,
            ...value,
        },
    }));

export const setModalStatus = (
    value: Partial<ProfilesListStore['modalStatus']>,
) =>
    setState(({ modalStatus }) => ({
        modalStatus: {
            ...modalStatus,
            ...value,
        },
    }));
