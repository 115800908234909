import { Text } from '@rf-smart-for-oraclecloud/ui';

export function Section({
    children,
    className,
}: Readonly<
    React.PropsWithChildren<{
        className?: string;
    }>
>) {
    return <div className={`__section ${className}`}>{children}</div>;
}

Section.Header = function Header({
    title,
    children,
}: Readonly<
    React.PropsWithChildren<{
        title: string;
    }>
>) {
    return (
        <div className="__header">
            <Text elementType="h2" className="__title">
                {title}
            </Text>
            {children}
        </div>
    );
};

Section.ActionHeader = function ActionHeader({
    children,
}: Readonly<React.PropsWithChildren>) {
    return <div className="__action-header">{children}</div>;
};

Section.Content = function Content({
    className,
    children,
}: React.PropsWithChildren<{
    className?: string;
}>) {
    const cn = className === undefined ? '' : ` ${className}`;
    return <div className={`__content${cn}`}>{children}</div>;
};
