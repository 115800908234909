import { Form } from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';
import { Section } from './Section';
import { ProfileDescriptionField, ProfileNameField } from './fields';

export function Details() {
    const intl = useAppIntl();

    return (
        <Section className="profile-detail-home-details">
            <Section.Header title={intl.profiles.details()} />
            <Section.Content>
                <Form>
                    <ProfileNameField />
                    <ProfileDescriptionField />
                </Form>
            </Section.Content>
        </Section>
    );
}
