import { Form, TextArea, TextInput } from '@rf-smart-for-oraclecloud/ui';
import { ActivityEditorLayout } from '@rf-smart-for-oraclecloud/wf-web';
import { useAppIntl } from '@@/intl';
import { useWorkflowEditorStore } from '../../../store';
import { selectRoot } from '../../../store/selectors';
import { updateActivitySpec } from '../../../store/actions';

export function WorkflowSettings() {
    const intl = useAppIntl();
    const workflow = useWorkflowEditorStore(selectRoot);

    const handleChangeName = (name: string) =>
        updateActivitySpec({ ...workflow, name });
    const handleChangeDescription = (description: string) =>
        updateActivitySpec({ ...workflow, description });

    return (
        <ActivityEditorLayout>
            <ActivityEditorLayout.Header>
                {workflow.name}
            </ActivityEditorLayout.Header>
            <ActivityEditorLayout.Content>
                <Form>
                    <TextInput
                        label={intl.common.name()}
                        onChange={handleChangeName}
                        value={workflow.name}
                    />
                    <TextArea
                        label={intl.common.description()}
                        onChange={handleChangeDescription}
                        value={workflow.description}
                    />
                </Form>
            </ActivityEditorLayout.Content>
        </ActivityEditorLayout>
    );
}
