import {
    MdOutlineSettings,
    MdCloudUpload,
    MdFileUpload,
    MdFileDownload,
} from 'react-icons/md';
import { IconButton } from '@rf-smart-for-oraclecloud/platform-ui';
import { useAppIntl } from '@@/intl';
import { Button, Radio, RadioGroup } from '@rf-smart-for-oraclecloud/ui';
import { useWorkflowEditorStore } from '../../store';
import { selectWorkflow, setDisplay } from '../../store/actions';
import { DisplayType } from '../../types';
import './styles.css';

export function WorkflowHeader({ onSave }: WorkflowHeaderProps) {
    const intl = useAppIntl();
    const display = useWorkflowEditorStore((s) => s.display);
    const handleChangeDisplay = (value: string) =>
        setDisplay(value as DisplayType);

    return (
        <>
            <div className="wf-editor-workflow-header__info">
                <RadioGroup
                    label="temp"
                    orientation="horizontal"
                    value={display}
                    onChange={handleChangeDisplay}
                >
                    <Radio value="design">{intl.config.designer()}</Radio>
                    <Radio value="code">{intl.config.codeBehind()}</Radio>
                    <Radio value="temp">{intl.config.tempManualEdit()}</Radio>
                </RadioGroup>
            </div>
            <div className="wf-editor-workflow-header__actions">
                <IconButton
                    icon={<MdCloudUpload />}
                    toolTip={intl.common.publish()}
                />
                <IconButton
                    icon={<MdFileDownload />}
                    toolTip={intl.common.export()}
                />
                <IconButton
                    icon={<MdFileUpload />}
                    toolTip={intl.common.import()}
                />
                <IconButton
                    icon={<MdOutlineSettings />}
                    toolTip={intl.common.configure()}
                    onClick={selectWorkflow}
                />
                <Separator />
                <Button family="subtle" variant="ghost">
                    {intl.common.cancel()}
                </Button>
                <Button onClick={onSave}>{intl.common.save()}</Button>
            </div>
        </>
    );
}

function Separator() {
    return <div className="wf-editor-workflow-header__action-separator" />;
}

type WorkflowHeaderProps = Readonly<{
    onSave: () => void;
}>;
