import { Button } from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';

export function ProfileDetailUserGroupActions({
    onSubmit,
    onCancel,
}: ProfileDetailUserGroupActionsProps) {
    const intl = useAppIntl();

    return (
        <>
            <div className="__action-group" />
            <div className="__action-group">
                <Button variant="ghost" family="subtle" onClick={onCancel}>
                    {intl.common.cancel()}
                </Button>
                <Button
                    variant="solid"
                    className="__primary-action __save-action"
                    onClick={onSubmit}
                >
                    {intl.common.done()}
                </Button>
            </div>
        </>
    );
}

type ProfileDetailUserGroupActionsProps = Readonly<{
    onSubmit: () => void;
    onCancel: () => void;
}>;
