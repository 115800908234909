import { BasicPage } from '@@/layout/BasicPage';
import { useAppIntl } from '@@/intl';

export function Settings() {
    const intl = useAppIntl();
    const breadCrumb = [intl.settings.settings()];

    return (
        <BasicPage breadCrumb={breadCrumb}>
            {intl.settings.settings()}
        </BasicPage>
    );
}
