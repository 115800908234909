import {
    Button,
    SlidingPanel,
    Form,
    RadioGroup,
    Radio,
} from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';
import { useProfilesListViewFilterSettings } from './useProfilesListViewFilterSettings';
import { StatusFilter } from './types';

export function FilterManager({ isOpen, onOpenChange }: FilterManagerProps) {
    const intl = useAppIntl();
    const { filters, setFilters, saveFilters } =
        useProfilesListViewFilterSettings();

    const handleSave = () => {
        saveFilters();
        onOpenChange(false);
    };

    const handleChangeStatusFilter = (value: string) => {
        const status = value as StatusFilter;
        setFilters({ status });
    };

    return (
        <SlidingPanel
            isOpen={isOpen}
            onOpenChange={onOpenChange}
            title={intl.common.manageFilters()}
        >
            <SlidingPanel.Body>
                <Form>
                    <RadioGroup
                        label={intl.profiles.status()}
                        onChange={handleChangeStatusFilter}
                        value={filters.status}
                    >
                        <Radio value="all">{intl.profiles.statusAll()}</Radio>
                        <Radio value="active">
                            {intl.profiles.statusActive()}
                        </Radio>
                        <Radio value="inactive">
                            {intl.profiles.statusInactive()}
                        </Radio>
                    </RadioGroup>
                </Form>
            </SlidingPanel.Body>
            <SlidingPanel.Footer>
                <Button onClick={() => onOpenChange(false)} variant="ghost">
                    {intl.common.cancel()}
                </Button>
                <Button onClick={handleSave}>{intl.common.save()}</Button>
            </SlidingPanel.Footer>
        </SlidingPanel>
    );
}

type FilterManagerProps = Readonly<{
    isOpen: boolean;
    onOpenChange: (isOpen: boolean) => void;
}>;
