import { useState, useCallback } from 'react';
import { useToast } from '@rf-smart-for-oraclecloud/ui';
import { useWorkflowsApi } from '@@/api/workflows';
import { CreateWorkflowRequest } from '@@/types';
import { useAppIntl } from '@@/intl';

export function useCreateWorkflow() {
    const intl = useAppIntl();
    const toast = useToast();
    const [data, setData] = useState<CreateWorkflowState>({
        loading: false,
    });
    const { create } = useWorkflowsApi();

    const createWorkflow = useCallback(
        (request: CreateWorkflowRequest) => {
            setData({ loading: true });
            return create(request)
                .catch(() =>
                    toast.showErrorToast(intl.profiles.workflowsFetchFailed()),
                )
                .finally(() => setData((s) => ({ ...s, loading: false })));
        },
        [create, intl, toast],
    );

    return {
        createWorkflow,
        ...data,
    };
}

type CreateWorkflowState = {
    loading: boolean;
};
