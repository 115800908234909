import { RouteObject } from 'react-router-dom';
import { WorkflowList } from './WorkflowList';
import { Editor } from './Editor';

export const configRoutes: RouteObject[] = [
    {
        index: true,
        element: <WorkflowList />,
    },
    {
        path: ':workflowId',
        element: <Editor />,
    },
];
