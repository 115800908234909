import { useMemo } from 'react';
import { MdOutlineImage } from 'react-icons/md';
import { AppMenu, type MenuItem } from '@rf-smart-for-oraclecloud/platform-ui';

const menuItems: (Omit<MenuItem, 'onClick'> & { url: string })[] = [
    {
        icon: MdOutlineImage,
        label: 'Execute',
        url:
            import.meta.env.BASE_URL === '/'
                ? import.meta.env.VITE_WORKFLOW_EXECUTE_URL
                : `${window.location.origin}/workflow-runner/`,
    },
    {
        icon: MdOutlineImage,
        label: 'Configuration',
        url: `/config`,
    },
    {
        icon: MdOutlineImage,
        label: 'Profiles',
        url: `/profiles`,
    },
    {
        icon: MdOutlineImage,
        label: 'Sessions',
        url: `/sessions`,
    },
    {
        icon: MdOutlineImage,
        label: 'Settings',
        url: `/settings`,
    },
];

export function Menu({ area }: MenuProps) {
    area.toString(); // todo set active menu

    const items = useMemo(() => menuItems, []);

    return <AppMenu items={items} />;
}

type MenuProps = Readonly<{
    area: string;
}>;
