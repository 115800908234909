import { useState } from 'react';
import { Button, ButtonProps } from '@rf-smart-for-oraclecloud/ui';
import {
    DialogTrigger,
    Dialog,
    Modal,
} from '@rf-smart-for-oraclecloud/platform-ui';

export function OpenModalButton({
    label,
    ariaLabel,
    icon,
    children,
    variant,
    family,
}: OpenModalButtonProps) {
    const [modalOpen, setModalOpen] = useState(false);

    const handleClick = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    return (
        <DialogTrigger>
            <Button
                icon={icon}
                onClick={handleClick}
                variant={variant}
                family={family}
                aria-label={ariaLabel}
            >
                {label}
            </Button>
            <Modal isOpen={modalOpen}>
                <Dialog>{children(handleClose)}</Dialog>
            </Modal>
        </DialogTrigger>
    );
}

type OpenModalButtonProps = Readonly<{
    label?: string;
    ariaLabel?: string;
    icon: React.ReactNode;
    variant?: ButtonProps['variant'];
    family?: ButtonProps['family'];
    children: (close: () => void) => React.ReactNode;
}>;
