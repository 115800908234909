import { ActivityDesignerViewLayout } from '@rf-smart-for-oraclecloud/wf-web';
import { plugins } from '@@/areas/config/plugins';
import { AddActivityButton } from '../AddActivityButton';
import { Connector } from '../Connector';
import { ActivityViewHeader } from './Header';
import { ActivityViewBody } from './Body';
import { ActivityViewProps } from './types';

export function ActvityView(props: ActvityViewWithAddButtonProps) {
    const { index, spec, isSelected, ...actions } = props;
    const activity = plugins.activities[spec.type];
    const designerProps = { activity, spec, isSelected, ...actions };

    return (
        <>
            <Connector />
            <ActivityDesignerViewLayout isSelected={isSelected}>
                <ActivityDesignerViewLayout.Header>
                    <ActivityViewHeader {...designerProps} />
                </ActivityDesignerViewLayout.Header>
                <ActivityDesignerViewLayout.Content>
                    <ActivityViewBody {...designerProps} />
                </ActivityDesignerViewLayout.Content>
            </ActivityDesignerViewLayout>
            <Connector />
            <AddActivityButton index={index} />
        </>
    );
}

type ActvityViewWithAddButtonProps = Readonly<
    Omit<ActivityViewProps, 'activity'> & {
        index: number;
    }
>;
