import { MdClose } from 'react-icons/md';
import { Button } from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';
import './styles.css';

const sizeMap: Record<SizeType, number> = {
    small: 30,
    medium: 50,
    large: 80,
};

export function BasicModalLayout({
    title,
    actions,
    children,
    onClose,
    size = 'medium',
}: BasicModalLayoutProps) {
    const intl = useAppIntl();

    return (
        <div
            className="layout-basic-modal"
            style={{
                width: `${sizeMap[size]}vw`,
            }}
        >
            <div className="__header">
                <h3>{title}</h3>
                <Button
                    icon={<MdClose />}
                    onClick={onClose}
                    variant="ghost"
                    family="subtle"
                    size="large"
                    aria-label={intl.common.close()}
                >
                    {null}
                </Button>
            </div>
            <div className="__body">{children}</div>
            <div className="__actions">{actions}</div>
        </div>
    );
}

type BasicModalLayoutProps = Readonly<
    React.PropsWithChildren<{
        title: string;
        actions?: React.ReactNode;
        size?: SizeType;
        onClose: () => void;
    }>
>;

type SizeType = 'small' | 'medium' | 'large';
