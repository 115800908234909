import oracle from '@rf-smart-for-oraclecloud/wf-plugin-oracle';
import custom from '@rf-smart-for-oraclecloud/wf-plugin-custom';
import {
    combinePlugins,
    createPluginMaps,
} from '@rf-smart-for-oraclecloud/wf-engine';
import { builtIn } from '@rf-smart-for-oraclecloud/wf-web';

export const plugins = createPluginMaps(
    combinePlugins(oracle, custom, builtIn),
);
