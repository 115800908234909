import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Loading } from '@rf-smart-for-oraclecloud/platform-ui';
import { ProfileForm } from './types';
import { useProfileDetail } from './useProfileDetail';
import { createProfile } from './defaults';

export function ProfileDetail() {
    const { profileId } = useParams();
    const formMethods = useForm<ProfileForm>({
        mode: 'onTouched',
        defaultValues: createProfile(),
    });
    const { profile, loading } = useProfileDetail(profileId);

    useEffect(() => {
        if (profile !== undefined) {
            formMethods.reset(profile);
        }
    }, [profile, formMethods]);

    if (loading) {
        return <Loading isLoading />;
    }

    if (profile === undefined) {
        return 'Not Found';
    }

    return (
        <FormProvider {...formMethods}>
            <Outlet />
        </FormProvider>
    );
}
