import { Button } from '@rf-smart-for-oraclecloud/ui';
import { useAppIntl } from '@@/intl';

export function ProfileDetailEntryActions({
    onSubmit,
    onCancel,
    onDelete,
    showDelete,
    submitDisabled,
}: ProfileDetailEntryActionsProps) {
    const intl = useAppIntl();

    return (
        <>
            <div className="__action-group">
                {showDelete && (
                    <Button variant="ghost" family="danger" onClick={onDelete}>
                        {intl.common.delete()}
                    </Button>
                )}
            </div>
            <div className="__action-group">
                <Button variant="ghost" family="subtle" onClick={onCancel}>
                    {intl.common.cancel()}
                </Button>
                <Button
                    variant="solid"
                    className="__primary-action __save-action"
                    onClick={onSubmit}
                    isDisabled={submitDisabled}
                >
                    {intl.common.done()}
                </Button>
            </div>
        </>
    );
}

type ProfileDetailEntryActionsProps = Readonly<{
    onSubmit: () => void;
    onCancel: () => void;
    onDelete: () => void;
    showDelete: boolean;
    submitDisabled: boolean;
}>;
