import { Profile, UserGroup } from '@rf-smart-for-oraclecloud/platform-ui';
import { ProfileForm } from './types';

export function toProfileForm(
    profile: Profile,
    userGroups: string[],
): ProfileForm {
    return {
        ...profile,
        entries: profile.entries.reduce(
            (entries, e) => ({
                ...entries,
                [e.id]: {
                    ...e,
                    with: JSON.stringify(e.with, null, 4),
                },
            }),
            {},
        ),
        userGroups,
    };
}

export function fromProfileForm(form: ProfileForm) {
    const { id, userGroups, ...values } = form;
    const profile = {
        ...values,
        entries: Object.values(values.entries).map((e) => ({
            ...e,
            with: JSON.parse(e.with),
            pluginConfigs: [],
        })),
    };

    return { id, userGroups, profile };
}

export function filterUserGroup(filter: string, userGroup: UserGroup) {
    const regEx = RegExp(filter, 'i');

    return regEx.test(userGroup.name) || regEx.test(userGroup.description);
}
