import { ListRequest, defineApi } from '@rf-smart-for-oraclecloud/platform-ui';
import {
    CreateWorkflowRequest,
    PagedResponse,
    UpdateWorkflowRequest,
    WorkflowDetail,
    WorkflowListItem,
} from '../types';

export const useWorkflowsApi = defineApi((api) => ({
    list: ({
        filter,
        page,
        pageSize,
    }: ListRequest): Promise<PagedResponse<WorkflowListItem>> =>
        api
            .get<
                PagedResponse<
                    WorkflowListItem & { created: string; lastModified: string }
                >
            >(`workflows?filter=${filter ?? ''}&page=${page ?? 1}&size=${pageSize ?? 20}`)
            .then((response) => ({
                ...response,
                items: response.items.map<WorkflowListItem>((wf) => ({
                    ...wf,
                    created: new Date(wf.created),
                    lastModified: new Date(wf.lastModified),
                })),
            })),

    get: (id: string) => api.get<WorkflowDetail | undefined>(`workflows/${id}`),

    create: (workflow: CreateWorkflowRequest) =>
        api.post<WorkflowDetail | undefined>(`workflows`, {
            ...workflow,
            data: {},
        }),

    update: (workflow: UpdateWorkflowRequest) =>
        api.patch<WorkflowDetail | undefined>(
            `workflows/${workflow.id}`,
            workflow,
        ),

    remove: (id: string) => api.delete(`workflows/${id}`),

    listCategories: () => api.get<string[]>('workflows/categories'),
}));
